import React from "react"
import Layout from "../components/layout"
import KwitantieTable from "../components/overviewTable"
import Login from "../context/login"

const Overview = () => {
  
  return (
    <Layout>
      <Login>
        <KwitantieTable />
      </Login>
    </Layout>
  )
}

export default Overview
