import { useAuth0 } from "@auth0/auth0-react"
import {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
} from "@chakra-ui/react"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { State } from "../context/kwitantieContext"
import Loader from "../context/loader"
import { groupBy } from "../utils/collection"
import { dateFormat, euroFormat } from "../utils/format"
import { serverlessAuth } from "./../utils/serverless"

const KwitantieTable = () => {
  const { getAccessTokenSilently, user } = useAuth0()
  console.log(user)
  const [data, setData] = useState<State[] | undefined>(undefined)

  const load = async () => {
    serverlessAuth(
      "/api/OverviewKwitanties",
      {},
      "GET",
      getAccessTokenSilently
    ).then(a => setData(a))
  }

  useEffect(() => {
    load()
  }, [])

  if (!data) {
    return <Loader />
  }

  const dataGrouped: State[] = groupBy(
    data.map(a => ({ ...a, year: new Date(a.kwitantieDate).getFullYear() })),
    "year"
  )
  var dataByYear = Object.keys(dataGrouped)
    .sort((l, r) => (l === r ? 0 : l < r ? 1 : -1))
    .map(year => ({
      year,
      items: dataGrouped[year],
      open: dataGrouped[year].filter(v => !v.isPaid).length,
    }))

  return (
    <>
      <div
        style={{
          position: "sticky",
          top: "0",
          padding: "0.4rem",
          backgroundColor: "#f5f5f5",
          display: "flex",
          justifyContent: "center",
          zIndex: 2,
          flexWrap: "wrap",
        }}
        className="noPrint"
      >
        <Button onClick={a => navigate("/item/?id=new")}>
          Nieuwe kwitantie
        </Button>
      </div>
      <div className="page content">
        <h1>Uw kwitanties</h1>

        <Accordion>
          {dataByYear.map(({ year, items, open }) => (
            <AccordionItem key={year}>
              <AccordionHeader>
                <Box flex="1" textAlign="left">
                  {year} {open > 0 && `(${open} open)`}
                </Box>
                <AccordionIcon />
              </AccordionHeader>
              <AccordionPanel pb={4}>
                <table>
                  <thead>
                    <tr>
                      <th>Datum</th>
                      <th>Naam</th>
                      <th>Bedrag</th>
                      <th>Betaald</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((a, i) => (
                      <tr
                        onClick={() => {
                          navigate("/item/?id=" + a.rowKey)
                        }}
                        className="hover"
                        key={i}
                      >
                        <td>{dateFormat(a.kwitantieDate)}</td>
                        <td>{a.kwitantie_number}</td>
                        <td>{euroFormat(a.totalPrice)}</td>
                        <td style={{ textAlign: "center" }}>
                          {a.isPaid ? "✔️" : ""}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th colSpan={2}></th>

                      <th colSpan={2}>
                        {euroFormat(
                          data.reduce(
                            (p, input: State) => input.totalPrice + p,
                            0
                          )
                        )}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        {data?.length === 0 && (
          <div>U hebt nog geen opgeslagen kwitanties.</div>
        )}
      </div>
    </>
  )
}

export default KwitantieTable
